'use client';

import { createContext, useState } from 'react';

export const AuthContext = createContext(null);

export default function AuthProvider({ user, children }) {

  // Initialize state with user data passed from the server
  const [authUser, setAuthUser] = useState(user);

  return (
    <AuthContext.Provider value={{ user: authUser, setUser: setAuthUser }}>
      {children}
    </AuthContext.Provider>
  );
}
